import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { withTranslation } from 'language/i18n';

const AreYouSeller = ({ t, classNameForBG }) => (
  <section className={classNameForBG}>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-15 col-sm-7 text-center text-sm-left">
          <h2 className="h2">{t('areYouSeller')}</h2>
          <p className="fs-20">{t('areYouSellerExplanation')}</p>
          <Link href="/machine-sales">
            <button
              type="submit"
              className="im-button im-button--primary im-button--center mt-3 mt-sm-5"
            >
              <span>{t('wantToSellMachine')}</span>
              <i className="far fa-long-arrow-right im-button__icon--right" />
            </button>
          </Link>
        </div>
        <div className="col-1 d-none d-sm-block text-center">
          <div className="separator separator--vertical" />
        </div>
        <div className="col-15 col-sm-7 mt-5 mt-sm-0 text-center text-sm-left">
          <div>
            <div className="circle" style={{ backgroundColor: '#FFCC05' }}>
              <Image
                src="/image/icons/mace-black.svg"
                width="90"
                height="54"
              />
            </div>
          </div>
          <h3 className="h4 mt-4 mb-4">{t('areYouSellerContentTitle')}</h3>
          <p>{t('areYousellerContentExplanation')}</p>
        </div>
      </div>
    </div>
  </section>
);

AreYouSeller.propTypes = {
  t: PropTypes.func,
  classNameForBG: PropTypes.string,
};

export default withTranslation('common')(AreYouSeller);
